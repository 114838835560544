const productsData = [
    {
        id: 1,
        tag: "featured-product",
        tagline: "15 inch, Super HD laptop",
        heroImage: "/images/products/1.png",
        images: [
            "/images/products/1.png",
            "/images/products/2.png",
            "/images/products/3.png",
            "/images/products/4.png",
        ],
        brand: "CGT",
        title: "CGT EVA",
        info: "Light weight high power device.",
        category: "device",
        type: "device",
        connectivity: "Wireless",
        finalPrice: 67999,
        originalPrice: 96999,
        quantity: 1,
        ratings: 1234,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 2,
        tag: "hero-product",
        tagline: "Buy TV using crypto, Invest with CGT now!",
        heroImage: "/images/products/5.png",
        images: [
            "/images/products/5.png",
            "/images/products/6.png",
            "/images/products/7.png",
            "/images/products/8.png",
        ],
        brand: "CGT",
        title: "CGT 2",
        info: "Light weight high power device.",
        category: "device",
        type: "device",
        connectivity: "Wireless",
        finalPrice: 14000,
        originalPrice: 19999,
        quantity: 1,
        ratings: 1321,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 3,
        tag: "featured-product",
        images: [
            "/images/products/9.png",
            "/images/products/10.png",
            "/images/products/11.png",
            "/images/products/12.png",
        ],
        brand: "CGT",
        title: "CGT SMART",
        info: "Smart electric device with long range.",
        category: "device",
        type: "device",
        connectivity: "Wireless",
        finalPrice: 21999,
        originalPrice: 24999,
        quantity: 1,
        ratings: 1321,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 4,
        tag: "hero-product",
        tagline: "Keep the noise out, or in. You choose.",
        heroImage: "/images/products/13.png",
        images: [
            "/images/products/13.png",
            "/images/products/14.png",
            "/images/products/15.png",
            "/images/products/16.png",
        ],
        brand: "CGT",
        title: "CGT TTX",
        info: "Smooth ride with no noice!",
        category: "device",
        type: "device",
        connectivity: "Wireless",
        finalPrice: 12999,
        originalPrice: 20999,
        quantity: 1,
        ratings: 1321,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 6,
        tag: "featured-product",
        images: [
            "/images/products/17.png",
            "/images/products/18.png",
            "/images/products/19.png",
            "/images/products/20.png",
        ],
        brand: "CGT",
        title: "CGT MARVEL",
        info: "Budget friendly device with long range.",
        category: "device",
        type: "device",
        connectivity: "Wireless",
        finalPrice: 28000,
        originalPrice: 32000,
        quantity: 1,
        ratings: 1321,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 7,
        tag: "featured-product",
        images: [
            "/images/products/21.png",
            "/images/products/22.png",
            "/images/products/23.png",
            "/images/products/24.png",
        ],
        brand: "CGT",
        title: "CGT MANTRA",
        info: "Stylish device with power.",
        category: "device",
        type: "device",
        connectivity: "Wireless",
        finalPrice: 2899,
        originalPrice: 3999,
        quantity: 1,
        ratings: 1321,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 8,
        tag: "featured-product",
        images: [
            "/images/products/25.png",
            "/images/products/26.png",
            "/images/products/27.png",
            "/images/products/28.png",
        ],
        brand: "CGT",
        title: "CGT LEGEND",
        info: "No Noise but full speed.",
        category: "device",
        type: "device",
        connectivity: "Wireless",
        finalPrice: 999,
        originalPrice: 1200,
        quantity: 1,
        ratings: 1321,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 9,
        tag: "featured-product",
        images: [
            "/images/products/29.png",
            "/images/products/30.png",
            "/images/products/31.png",
            "/images/products/32.png",
        ],
        brand: "CGT",
        title: "CGT KETO PLUS",
        info: "Light heavy with efficiant.",
        category: "truck",
        type: "truck",
        connectivity: "Wireless",
        finalPrice: 2100,
        originalPrice: 3000,
        quantity: 1,
        ratings: 1321,
        rateCount: 5,
        path: "/product-details/",
    },
    
   
];

export default productsData;